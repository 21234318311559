@import 'bootstrap/scss/bootstrap';

@font-face {
	font-family: "MetricHPE";
	font-weight: 400;
	src:
		url("./fonts/MetricHPE-Web-Regular.woff") format("woff"),
		url("./fonts/MetricHPE-App-Regular.ttf") format("truetype"),
		url("./fonts/MetricHPE-Web-Regular.eot") format("embedded-opentype"),
		url("./fonts/MetricHPE-Desktop-Regular.otf") format("opentype");
}

@font-face {
	font-family: "MetricHPE";
	font-weight: 800;
	src:
		url("./fonts/MetricHPE-Web-Bold.woff") format("woff"),
		url("./fonts/MetricHPE-App-Bold.ttf") format("truetype"),
		url("./fonts/MetricHPE-Web-Bold.eot") format("embedded-opentype"),
		url("./fonts/MetricHPE-Desktop-Bold.otf") format("opentype");
}

.grow-wrap {
  display: grid;
}
.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  padding: 0.5rem;
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
}
